import FoodGridSingle from "./FoodGridSingle";
import FoodGridSingleSide from "./FoodGridSingleSide";
import FoodGridGroup from './FoodGridGroup';
import SelectionNavigator from './SelectionNavigator';
import { preloadImage } from "../lib/Utils";

const SelectionSection = (props) => {
  const {
    coordinates,
    places,
    index,
    onScrollLeft,
    onScrollRight,
    isGrouped,
    isAtStart,
    isAtEnd,
    placeSelected,
    setPlaceSelected
  } = props;

  const leftImg = isAtStart ? '' : places[index-1].img;
  const rightImg = isAtEnd ? '' : places[index+1].img;

  const onLeftNavigate = () => {
    onScrollLeft();
  };

  const onRightNavigate = () => {
    for (let i = 1; i <= 3; i++) {
      const newIndex = index + i;
      if (newIndex < places.length)
        preloadImage(places[newIndex].img);
      else
        break;
    }
    onScrollRight();
  };

  return (
    <div class="gallery-container">
      {placeSelected && <SelectionNavigator type="left" onClick={() => setPlaceSelected(null)} />}
      {!placeSelected && <SelectionNavigator type={!isAtStart && 'left'} onClick={onLeftNavigate} />}
      {!isGrouped && <>
        <FoodGridSingleSide src={leftImg} />
        <FoodGridSingle place={places[index]} coordinates={coordinates} />
        <FoodGridSingleSide src={rightImg} />
      </>}
      {isGrouped && !placeSelected && <FoodGridGroup places={places[index]} coordinates={coordinates} onSelect={setPlaceSelected} />}
      {isGrouped && placeSelected && <FoodGridSingle place={placeSelected} coordinates={coordinates} />}
      {!placeSelected && <SelectionNavigator type={!isAtEnd && 'right'} onClick={onRightNavigate} />}
      {placeSelected && <SelectionNavigator type={null} />}
    </div>
  );
};

export default SelectionSection;
