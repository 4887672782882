import { isSafari } from "./Device";

export const FUNCTION_URL = 'https://wgf-opl4fvi.wl.gateway.dev/core'

export const SESSION_TOKEN = '';

// const TEST_MODE = true;
const TEST_MODE = false;

const MOCK_LOCATION_DATA = {
  "Fernvale": [
      "Fernvale NSW, Australia",
      "ChIJXXdfgzfwkGsRMPZDkLQJBgQ"
  ],
  "Fernvale Lane": [
      "Fernvale Lane, Singapore",
      "EhhGZXJudmFsZSBMYW5lLCBTaW5nYXBvcmUiLiosChQKEgnNiSEMYxbaMRHMRXhBl3hw8hIUChIJdZOLiiMR2jERxPWrUs9peIg"
  ],
  "Fernvale Link": [
      "Fernvale Link, Singapore",
      "EhhGZXJudmFsZSBMaW5rLCBTaW5nYXBvcmUiLiosChQKEglv8RaEehbaMRGOdln84pgS2RIUChIJyY4rtGcX2jERIKTarqz3AAQ"
  ]
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const searchLocation = (input, successCb, errCb) => {
  if (TEST_MODE) {
    const result = MOCK_LOCATION_DATA;
    return successCb(Object.keys(result)
    .map((place) => ({
      id: result[place][1],
      name: place,
      address: result[place][0]
    })));
  }
  const { name } = input;
  fetch(`${FUNCTION_URL}?input=${name}&sessiontoken=${SESSION_TOKEN}&action=autocomplete`)
    .then((response) => response.json())
    .then((result) => successCb(Object.keys(result)
      .map((place) => ({
        id: result[place][1],
        name: place,
        address: result[place][0]
      }))))
    .catch((err) => errCb(err));
};

export const geocode = (placeId, successCb, errCb) => {
  fetch(`${FUNCTION_URL}?place_id=${placeId}&sessiontoken=${SESSION_TOKEN}&action=geocoding`)
    .then((response) => response.json())
    .then((result) => successCb({
      lat: result['0'].lat_long[0],
      lng: result['0'].lat_long[1]
    }))
    .catch((err) => errCb(err));
};

export const gotoPlaceLink = (placeId) => {
  const url = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
  if (isSafari()) {
    window.location.assign(url)
  } else {
    window.open(url, '_blank')
  }
};

export const gotoGoogleMapNavMode = (originLat, originLng, destLat, destLng) => {
  const url = `https://www.google.com/maps/dir/?api=1&origin=${originLat},${originLng}&destination=${destLat},${destLng}`
  if (isSafari()) {
    window.location.assign(url)
  } else {
    window.open(url, '_blank')
  }
};