const SelectionNavigator = (props) => {
  const { type, onClick } = props;

  const getSourceImage = (type) => {
    switch (type) {
      case 'left':
        return 'https://img.icons8.com/ios-filled/50/less-than.png';

      case 'right':
        return 'https://img.icons8.com/ios-filled/50/more-than.png';
    
      default:
        break;
    }
  };

  const img = getSourceImage(type);

  const onButtonClick = () => {
    if (img) onClick();
  };

  return (
    <div class="gallery-selector" onClick={onButtonClick}>
      {img && <img class="gallery-cursor" width="50" height="50" src={img} alt="less-than"/>}
      {!img && <div class="gallery-selector-none" />}
    </div>
  );
};

export default SelectionNavigator;
