export const getUserGPSLocation = (callback, onGpsNotFound) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      callback(position.coords.latitude, position.coords.longitude);
    }, () => {
      onGpsNotFound();
    });
  } else {
    onGpsNotFound();
  }
};
