import FoodGridSingle from "./FoodGridSingle";

const FoodGridGroup = (props) => {
  const { places, coordinates, onSelect } = props;
  return (
    <div class="thumb-group">
      {places.map((place) => (<FoodGridSingle groupView coordinates={coordinates} place={place} onClick={onSelect} />))}
    </div>
  );
};

export default FoodGridGroup;
