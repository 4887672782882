import ReviewItem from "./ReviewItem";
import Spinner from "./Spinner";
import ReviewPrompt from './ReviewPrompt';

const ReviewSection = (props) => {
  const { reviews, isLoading, onClick, isGrouped, placeSelected, hidden } = props;
  const allowReviews = !hidden && (!isGrouped || !!placeSelected);
  return (
    <div class="reviews-container">
      {allowReviews && !reviews && !isLoading && <ReviewPrompt onClick={onClick} />}
      {allowReviews && isLoading && <Spinner />}
      {allowReviews && reviews && reviews.map((review) => (<ReviewItem review={review} />))}

      {allowReviews && reviews && !reviews.length && <div class="review-prompt"><p>No reviews yet</p></div>}
    </div>
  );
};

export default ReviewSection;
