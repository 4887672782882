import { useState } from 'preact/hooks';
import {
  logDragDistance,
  logOpenDistance,
  logOpenFilter,
  logOpenGroup,
  logResetFilter,
  logSelectCuisineFilter,
  logSelectDietFilter,
  logSelectDistance,
  logSelectGroup
} from '../lib/Analytics';
import {
  DIET_TAG_NON_VEGETARIAN,
  DIET_TAG_VEGETARIAN
} from '../lib/Filter';

const SELECTED_GROUP_BY = 1;
const SELECTED_FILTER_BY = 2;
const SELECTED_DISTANCE = 3;

const GROUP_CUISINE = 'cuisine';
const GROUP_DIET = 'diet';

const FILTERS_CUISINE = [
  { label: 'American', value: 'c:american_restaurant' },
  { label: 'Bakery', value: 'c:bakery' },
  { label: 'Barbecue', value: 'c:barbecue_restaurant' },
  { label: 'Brazilian', value: 'c:brazilian_restaurant' },
  { label: 'Breakfast', value: 'c:breakfast_restaurant' },
  { label: 'Brunch', value: 'c:brunch_restaurant' },
  { label: 'Cafe', value: 'c:cafe' },
  { label: 'Chinese', value: 'c:chinese_restaurant' },
  { label: 'French', value: 'c:french_restaurant' },
  { label: 'Greek', value: 'c:greek_restaurant' },
  { label: 'Hamburger', value: 'c:hamburger_restaurant' },
  { label: 'Ice Cream', value: 'c:ice_cream_shop' },
  { label: 'Indian', value: 'c:indian_restaurant' },
  { label: 'Indonesian', value: 'c:indonesian_restaurant' },
  { label: 'Italian', value: 'c:italian_restaurant' },
  { label: 'Japanese', value: 'c:japanese_restaurant' },
  { label: 'Korean', value: 'c:korean_restaurant' },
  { label: 'Lebanese', value: 'c:lebanese_restaurant' },
  { label: 'Mediterranean', value: 'c:mediterranean_restaurant' },
  { label: 'Mexican', value: 'c:mexican_restaurant' },
  { label: 'Middle Eastern', value: 'c:middle_eastern_restaurant' },
  { label: 'Pizza', value: 'c:pizza_restaurant' },
  { label: 'Sandwich', value: 'c:sandwich_shop' },
  { label: 'Seafood', value: 'c:seafood_restaurant' },
  { label: 'Spanish', value: 'c:spanish_restaurant' },
  { label: 'Steak', value: 'c:steak_house' },
  { label: 'Thai', value: 'c:thai_restaurant' },
  { label: 'Turkish', value: 'c:turkish_restaurant' },
  { label: 'Vietnamese', value: 'c:vietnamese_restaurant' }
];

const FILTERS_DIET = [
  // { label: 'Vegan', value: 'd:vegan_restaurant' },
  // { label: 'Vegetarian', value: 'd:vegetarian_restaurant' },
  { label: 'Vegetarian', value: DIET_TAG_VEGETARIAN },
  { label: 'Non-Vegetarian', value: DIET_TAG_NON_VEGETARIAN }
];

const FilterBar = (props) => {
  // filter display
  const [selection, setSelection] = useState(0);

  // distance setting
  const { distance, setDistance } = props;
  const [selectedDistance, setSelectedDistance] = useState(distance);

  // group setting
  const { group, setGroup } = props;

  // filter setting
  const { filter, setFilter } = props;

  const onClick = (val) => {
    setSelection(selection === val ? 0 : val);
    switch (val) {
      case SELECTED_GROUP_BY:
        logOpenGroup();
        break;
      case SELECTED_FILTER_BY:
        logOpenFilter();
        break;
      case SELECTED_DISTANCE:
        logOpenDistance();
        break;
    }
  };

  const onSelectDistance = (e) => {
    const dist = e.target.value;
    setSelectedDistance(dist);
    logDragDistance();
  };

  const onApplyDistance = () => {
    setDistance(selectedDistance);
    setSelection(0);
    logSelectDistance(selectedDistance);
  };

  const onSelectedGroup = (selectedGroup) => {
    if (group === selectedGroup) {
      setGroup(null);
    } else {
      setGroup(selectedGroup);
      logSelectGroup(selectedGroup);
    }
  };

  const onSelectCuisineFilter = (selectedFilter) => {
    if (filter?.cuisine !== selectedFilter) {
      setFilter({
        ...filter,
        cuisine: selectedFilter
      });
      logSelectCuisineFilter(selectedFilter);
    }
    setSelection(0);
  };

  const onSelectDietFilter = (selectedFilter) => {
    if (filter?.diet !== selectedFilter) {
      setFilter({
        ...filter,
        diet: selectedFilter
      });
      logSelectDietFilter(selectedFilter);
    }
    setSelection(0);
  };

  const onResetFilter = () => {
    setFilter(null);
    setSelection(0);
    logResetFilter();
  };

  return (
    <>
      <div class="filter-bar">
        <button onClick={() => onClick(SELECTED_GROUP_BY)}>🗂️ Group By</button>
        <button onClick={() => onClick(SELECTED_FILTER_BY)}>🔽 Filter By</button>
        <button onClick={() => onClick(SELECTED_DISTANCE)}>📍 5 km</button>
      </div>

      {selection == SELECTED_GROUP_BY && <div class="filter-popup">
        <button class="filter-box-close" onClick={() => onClick(selection)}>✕</button>
        <div class="filter-box-g">
          <div class="filter-box-gr">
            <input type="radio" id="group-cuisine" name="Cuisine" value="cuisine"
              checked={group === GROUP_CUISINE}
              onClick={() => onSelectedGroup(GROUP_CUISINE)}
            />
            <label for="group-cuisine">Cuisine</label><br />
          </div>
          <div class="filter-box-gr">
            <input type="radio" id="group-diet" name="Diet" value="diet"
              checked={group === GROUP_DIET}
              onClick={() => onSelectedGroup(GROUP_DIET)}
            />
            <label for="group-diet">Diet</label><br />
          </div>
        </div>
      </div>}

      {selection == SELECTED_FILTER_BY && <div class="filter-popup filter-box-f">
        <button class="filter-box-close" onClick={() => onClick(selection)}>✕</button>
        <p>Cuisine</p>
        <div class="filter-row">
          {FILTERS_CUISINE.map((i) => (
            <div
              class={i.value === filter?.cuisine ? 'filter-row-item-selected' : 'filter-row-item'}
              onClick={() => onSelectCuisineFilter(i.value)}>
              <p>{i.label}</p>
            </div>
          ))}
        </div>
        <br />
        <p>Diet</p>
        <div class="filter-row">
          {FILTERS_DIET.map((i) => (
            <div
              class={i.value === filter?.diet ? 'filter-row-item-selected' : 'filter-row-item'}
              onClick={() => onSelectDietFilter(i.value)}>
              <p>{i.label}</p>
            </div>
          ))}
        </div>
        <br />
        <button class="filter-box-apply" onClick={() => onResetFilter()}>Reset</button>
      </div>}

      {selection == SELECTED_DISTANCE && <div class="filter-popup filter-box-d">
        <button class="filter-box-close" onClick={() => onClick(selection)}>✕</button>
        <input class="dist-slider" id="dist-sel"
          type="range" min="1" max="10"
          value={selectedDistance.toString()}
          onInput={onSelectDistance} />
        <p>{selectedDistance} km</p>
        <button class="filter-box-apply" onClick={() => onApplyDistance()}>Apply</button>
      </div>}
    </>
  );
};

export default FilterBar;
