const ReviewPrompt = (props) => {
  const { onClick } = props;
  return (
    <div class="review-prompt" onClick={onClick}>
      <p>Click for reviews</p>
      <img width="20" height="20" src="https://img.icons8.com/ios/50/expand-arrow--v1.png" alt="expand-arrow--v1"/>
    </div>
  );
};

export default ReviewPrompt;
