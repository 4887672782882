import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCR2QYJTJBERYPnTEwDRqVkZb0CQzYf5Co",
  authDomain: "halogen-force-360503.firebaseapp.com",
  projectId: "halogen-force-360503",
  storageBucket: "halogen-force-360503.appspot.com",
  messagingSenderId: "53789909598",
  appId: "1:53789909598:web:f9b2682784ae89168e8acf",
  measurementId: "G-HVFRV552G6"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logSearchBar = () => {
  logEvent(analytics, 'search_places_from_search_bar');
};

export const logSearchBarClear = () => {
  logEvent(analytics, 'search_bar_clear');
};

export const logSearchOptionSelect = () => {
  logEvent(analytics, 'search_option_select');
};

export const logGps = () => {
  logEvent(analytics, 'gps_search');
};

export const logGpsClicked = () => {
  logEvent(analytics, 'gps_manual_select');
};

export const logRevealReviews = () => {
  logEvent(analytics, 'review_show');
};

export const logLikeClicked = () => {
  logEvent(analytics, 'action_like');
};

export const logNavigateClick = () => {
  logEvent(analytics, 'action_navigate');
};

export const logLinkClick = () => {
  logEvent(analytics, 'action_link');
};

export const logOpenFilter = () => {
  logEvent(analytics, 'filter_open');
};

export const logResetFilter = () => {
  logEvent(analytics, 'filter_reset');
};

export const logSelectCuisineFilter = (filter) => {
  logEvent(analytics, 'filter_select_cuisine', { filter });
};

export const logSelectDietFilter = (filter) => {
  logEvent(analytics, 'filter_select_diet', { filter });
};

export const logOpenGroup = () => {
  logEvent(analytics, 'group_open');
};

export const logSelectGroup = (group) => {
  logEvent(analytics, 'group_select', { group });
};

export const logGroupItemClick = (group) => {
  logEvent(analytics, 'group_place_click');
};

export const logOpenDistance = () => {
  logEvent(analytics, 'distance_open');
};

export const logDragDistance = () => {
  logEvent(analytics, 'distance_drag');
};

export const logSelectDistance = (distance) => {
  logEvent(analytics, 'distance_select', { distance });
};
