import { useState } from 'preact/hooks';

import SearchBar from "./SearchBar";
import SearchItem from "./SearchItem";
import { debounce, searchLocation } from '../lib/Map';
import {
  logSearchBar,
  logSearchBarClear,
  logSearchOptionSelect
} from '../lib/Analytics';

const NavBar = (props) => {
  const { place, onPlaceChange, onGPSClick, hasGPS, setError } = props;
  const [suggestedPlaces, setSuggestedPlaces] = useState([]);

  const onPlaceInputChange = debounce((placeInput) => {
    onPlaceChange(placeInput);
    searchLocation(placeInput, (data) => {
      setSuggestedPlaces(data);
      logSearchBar();
    }, (err) => {
      setError(err);
    });
  }, 800);

  const onPlaceSelect = (place) => {
    setSuggestedPlaces([]);
    onPlaceChange(place);
    logSearchOptionSelect();
  };

  const onClearSearch = () => {
    setSuggestedPlaces([]);
    onPlaceChange(null);
    logSearchBarClear();
  };
  
  return (
    <div class="nav-bar">
			<SearchBar
        place={place}
				onPlaceChange={onPlaceInputChange}
        onGPSClick={onGPSClick}
        hasGPS={hasGPS}
        onClear={onClearSearch}
      />
      <div class="search-popup">
        {suggestedPlaces.map((place) => (
          <SearchItem place={place} onClick={(place) => onPlaceSelect(place)} />)
        )}
      </div>
    </div>
  );
};

export default NavBar;
