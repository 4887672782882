export const FUNCTION_URL = 'https://wgf-opl4fvi.wl.gateway.dev/core'

export const SESSION_TOKEN = '';

// const TEST_MODE = true;
const TEST_MODE = false;

const MOCK_LISTING_DATA = {
  'https://admin.misstamchiak.com/wp-content/uploads/2016/12/16069838051_c75eb4dd99_o-e1481721167937.jpg': {
    name: 'HKM 1',
    vicinity: 'Place 1',
    rating: 4.2,
    diet: 'carnivore',
    cuisine: 'chinese',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://cdn.loveandlemons.com/wp-content/uploads/2021/04/green-salad.jpg': {
    name: 'Salad 1',
    vicinity: 'Place 1',
    rating: 4.2,
    diet: 'vegetarian',
    cuisine: 'western',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://eatbook.sg/wp-content/uploads/2017/06/Kim-Keat-Close-Up.jpg': {
    name: 'The Best Hokkien Mee Place in Singapore!',
    vicinity: 'Place 1',
    rating: 4.6,
    diet: 'carnivore',
    cuisine: 'chinese',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://www.unileverfoodsolutions.com.my/dam/global-ufs/mcos/SEA/calcmenu/recipes/MY-recipes/general/chicken-chop-kegemaran-malaysia/main-header.jpg': {
    name: 'Chicken 2',
    vicinity: 'Place 2',
    rating: 3.2,
    diet: 'carnivore',
    cuisine: 'western',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://upload.wikimedia.org/wikipedia/commons/6/61/Singapore_style_Hokkien_mee.jpg': {
    name: 'HKM 3',
    vicinity: 'Place 1',
    rating: 4.2,
    diet: 'carnivore',
    cuisine: 'chinese',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://www.barossafinefoods.com.au/glide-cache/containers/main/2020_bff_chickenchops_southernfriedchicken_website-1.jpg/e1e15eaf9db66b9a6bd68361010914b4.jpg': {
    name: 'Chicken 3',
    vicinity: 'Place 3',
    rating: 3.2,
    diet: 'carnivore',
    cuisine: 'western',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://feelgoodfoodie.net/wp-content/uploads/2019/02/Mediterranean-Chopped-Salad-12.jpg': {
    name: 'Salad 2',
    vicinity: 'Place 2',
    rating: 3.2,
    diet: 'vegatarian',
    cuisine: 'western',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'https://www.shelikesfood.com/wp-content/uploads/2022/06/nRainbow-Veggie-Salad-9921.jpg': {
    name: 'HKM 1',
    vicinity: 'Place 1',
    rating: 4.2,
    diet: 'vegatarian',
    cuisine: 'western',
    location: {
      "lat": 1.2811866,
      "lng": 103.8503404
    }
  },
  'next_page_token': '123'
};

const MOCK_REVIEW_DATA = [
  {
    userid: '123',
    name: 'Tester',
    ts: '1d',
    body: 'Test only'
  },
  {
    userid: '123',
    name: 'Tester',
    ts: '1d',
    body: 'Test only'
  },
  {
    userid: '123',
    name: 'Tester',
    ts: '1d',
    body: 'Test only'
  },
  {
    userid: '123',
    name: 'Tester',
    ts: '1d',
    body: 'Test only'
  },
  {
    userid: '123',
    name: 'Tester',
    ts: '1d',
    body: 'Test only'
  }
];

const getGoogleImageLink = (ref) => {
  if (ref.startsWith('http')) return ref;
  return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=360&photo_reference=${ref}&key=AIzaSyA1a_G4n1ppX5PP8FPA2mIeV2th0CPC9hw`;
};

export const getNearbyListings = async (coordinates, nextPageToken, distance, filterBy, successCb, errCb) => {
  if (TEST_MODE) {
    const result = MOCK_LISTING_DATA;
    successCb(Object.keys(result)
      .filter((id) => id !== 'next_page_token')
      .map((id) => ({
        img: getGoogleImageLink(id),
        name: result[id].name,
        short_address: result[id].vicinity,
        full_address: result[id].formatted_address,
        place_id: result[id].place_id,
        lat: result[id].location.lat,
        lng: result[id].location.lng,
        rating: result[id].rating,
        total_ratings: result[id].user_ratings_total,
        website: result[id].website,
        diet: result[id].diet,
        cuisine: result[id].cuisine
      })), result.next_page_token)
    return;
  }
  
  const url = nextPageToken
    ? `${FUNCTION_URL}?action=nextnearbysearch&next_page_token=${nextPageToken}&sessiontoken=${SESSION_TOKEN}`
    : `${FUNCTION_URL}?action=nearbysearch&location=${coordinates.lat},${coordinates.lng}&type=restaurant&version=v2`

  fetch(url)
    .then((response) => response.json())
    .then((result) => successCb(Object.keys(result)
      .filter((id) => id !== 'next_page_token')
      .map((id) => ({
        img: getGoogleImageLink(id),
        name: result[id].name,
        short_address: result[id].vicinity,
        full_address: result[id].formatted_address,
        place_id: result[id].place_id,
        lat: result[id].location.lat,
        lng: result[id].location.lng,
        rating: result[id].rating,
        total_ratings: result[id].user_ratings_total,
        website: result[id].website,
        diet: result[id].diet,
        cuisine: result[id].cuisine
      })), result.next_page_token))
    .catch((err) => errCb(err))
};

export const getReviews = async (place, successCb, errCb) => {
  if (TEST_MODE) {
    successCb(MOCK_REVIEW_DATA);
    return;
  }

  successCb([]);
};
