const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const FoodGridSingleSide = (props) => {
  const { src } = props;

  return (
    <div className="thumb-secondary">
      <img className={src ? 'thumb-back' : ''} src={src ? src : EMPTY_IMAGE} />
    </div>
  );
};

export default FoodGridSingleSide;
