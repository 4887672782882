import { logGpsClicked } from "../lib/Analytics";

const SearchBar = (props) => {
  const { place, onPlaceChange, onGPSClick, hasGPS, onClear } = props;

  const onPlaceInput = (e) => {
    onPlaceChange({ name: e.target.value });
  };

  const onLocationManualClick = () => {
    onGPSClick();
    logGpsClicked();
  };

  return (
    <div class="search-bar">
      <div class="search-bar-input">
        {hasGPS && <button
          class="nav-button hover-button"
          onClick={onLocationManualClick}>📍</button>}
        <input
          class="search-input"
          type="text"
          placeholder="Enter location"
          value={place?.name ?? ''}
          onInput={onPlaceInput}
        />
        {place && <button
          class="nav-button hover-button"
          onClick={onClear}>✖️</button>}
      </div>
      {/* <button class="nav-button search-button hover-button">🔎</button> */}
    </div>
  );
};

export default SearchBar;
