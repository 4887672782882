import { render } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import * as Sentry from '@sentry/react';

import './style.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import FilterBar from './components/FilterBar';
import LogoSection from './components/LogoSection';
import SelectionSection from './components/SelectionSection';
import ReviewSection from './components/ReviewSection';
import ActionSection from './components/ActionSection';
import Spinner from './components/Spinner';
import { getUserGPSLocation } from './lib/Location';
import { geocode, gotoGoogleMapNavMode, gotoPlaceLink } from './lib/Map';
import { getNearbyListings, getReviews } from './lib/Lambda';
import { filterIsVegetarian, getFilterCuisineValue } from './lib/Filter';
import {
	logGps,
	logLikeClicked,
	logLinkClick,
	logNavigateClick,
	logRevealReviews
} from './lib/Analytics';

Sentry.init({
  dsn: "https://e846f38798ad1e8ad8f2ba420a73cbb6@o4506896436166656.ingest.us.sentry.io/4506896438460416",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function App() {
	// filters
	const [coordinates, setCoordinates] = useState(null);
	const [hasGps, setHasGps] = useState(true);
	const [place, setPlace] = useState(null);
	const [distance, setDistance] = useState(5);
	const [groupedBy, setGroupedBy] = useState(null);
	const [filterBy, setFilterBy] = useState(null);
	
	// results
	const [hasSearched, setHasSearched] = useState(false);
	const [results, setResults] = useState([]);
	const [unfiltered, setUnfiltered] = useState([]);
	const [reviews, setReviews] = useState(null);
	const [itemIndex, setItemIndex] = useState(0);
	const [nextPageToken, setNextPageToken] = useState(null);
	const [nextLoadIndex, setNextLoadIndex] = useState(null);
	const [lastCalledToken, setLastCalledToken] = useState(null);
	const [placeSelected, setPlaceSelected] = useState(null);
	const [error, setError] = useState(null);

	// loading states
	const [isResultLoading, setIsResultLoading] = useState(true);
	const [isReviewLoading, setIsReviewLoading] = useState(false);
	const minSwipeDistance = 50;

	// gestures
	const [touchStart, setTouchStart] = useState(null)
	const [touchEnd, setTouchEnd] = useState(null)

	useEffect(() => getGPSLocation(), []);

	/** FILTERS **/

	const getGPSLocation = () => {
		getUserGPSLocation((lat, lng) => {
			const coords = { lat, lng };
			setCoordinates(coords);
			setPlace(null);
			loadResults(coords, distance, filterBy);
			console.log("Location is :", coords);
			logGps();
		}, () => {
			setIsResultLoading(false);
			setHasGps(false);
		});
	};

	const onPlaceChange = (newPlace) => {
		setPlace(newPlace);
		if (!newPlace?.id) return;
		setIsResultLoading(true);
		emptyResults();
		geocode(newPlace.id, (coords) => {
			setCoordinates(coords);
			loadResults(coords, distance, filterBy);
		}, (err) => {
			setError(err);
			setIsResultLoading(false);
		});
	};

	const onDistanceChange = (newDistance) => {
		if (distance === newDistance) return;
		setDistance(newDistance);
		emptyResults();
		loadResults(coordinates, newDistance, filterBy);
	}

	const filterResults = (places, filter) => {
		const cuisine = getFilterCuisineValue(filter);
		const dietIsVeg = filterIsVegetarian(filter);
		return places.filter((place) => !cuisine || cuisine === place['cuisine'])
			.filter((place) => !dietIsVeg || dietIsVeg === place['is_veg']);
	};

	const onFilterChange = (newFilter) => {
		if (filterBy?.cuisine === newFilter?.cuisine
				&& filterBy?.diet === newFilter?.diet) {
			return;
		}
		if (!filterBy) {
			const unfiltered = groupedBy ? flattenGroup(results) : results;
			setUnfiltered(unfiltered);
			setResults(filterResults(unfiltered, newFilter));
		} else {
			setResults(filterResults(unfiltered, newFilter));
		}
		setItemIndex(0);
		setGroupedBy(null);
		setFilterBy(newFilter);
		// emptyResults();
		// loadResults(coordinates, distance, newFilter);
	};

	const onGroup = (newGroup) => {
		const alreadyGrouped = !!groupedBy;
		if (groupedBy === newGroup) return;
		setItemIndex(0);
		setGroupedBy(newGroup);
		if (!newGroup) {
			setResults(flattenGroup(results));
		} else if (alreadyGrouped) {
			setResults(groupListing(newGroup, flattenGroup(results)));
		} else {
			const groupResults = groupListing(newGroup, filterBy ? unfiltered : results);
			setResults(groupResults);
			if (filterBy) {
				setUnfiltered([]);
				setFilterBy(null);
			}
		}
	};

	const flattenGroup = (results) => {
		let listings = [];
		results.forEach((places) => {
			listings = [...listings, ...places]
		});
		return listings;
	};

	const groupListing = (groupedBy, listings) => {
		const groupings = {};
		listings.forEach((place) => {
			const label = place[groupedBy] ?? 'none';
			if (!(label in groupings)) groupings[label] = [];
			groupings[label].push(place);
		});
		const results = [];
		Object.keys(groupings).forEach((group) => {
			groupings[group].forEach((place) => {
				if (results.length === 0 || results[results.length - 1].length === 4) {
					results.push([place]);
				} else {
					results[results.length - 1].push(place);
				}
			});
		});
		return results;
	};

	/** RESULTS **/

	const emptyResults = () => {
		resetReviews();
		setResults([]);
		setItemIndex(0);
		setNextPageToken(null);
		setNextLoadIndex(null);
		setLastCalledToken(null);
	}

	const generateNextLoadIndex = () => setNextLoadIndex(Math.max(0, results.length - 10));

	const handleResults = (listings, nextPageToken) => {
		const startIdx = results.length;
		const newResults = groupedBy
			? groupListing(listings)
			: listings.map((place, i) => ({ ...place, idx: startIdx + i }));
		if (filterBy) {
			setUnfiltered([...unfiltered, ...newResults]);
			setResults([...results, ...filterResults(newResults, filterBy)]);
		} else {
			setResults([...results, ...newResults])
		}
		setIsResultLoading(false);
		if (nextPageToken) setNextPageToken(nextPageToken);
		generateNextLoadIndex();
	};

	const handleError = (err) => {
		setError(err);
		setIsResultLoading(false);
	};

	const loadResults = (coordinates, distance, filterBy) => {
		if (!coordinates) return;
		setHasSearched(true);
		setIsResultLoading(true);
		setError(null);
		getNearbyListings(coordinates, null, distance, filterBy, handleResults, handleError);
	};

	const loadNextPageResults = () => {
		if (!nextPageToken || lastCalledToken === nextPageToken) return;
		setLastCalledToken(nextPageToken);
		getNearbyListings(null, nextPageToken, distance, filterBy, handleResults, handleError);
	};

	const loadReview = () => {
		setIsReviewLoading(true);
		setError(null);
		logRevealReviews();
		getReviews(results[itemIndex], (reviews) => {
			setReviews(reviews);
			setIsReviewLoading(false);
		}, (err) => {
			setReviews([]);
			setIsReviewLoading(false);
		})
	};

	const resetReviews = () => setReviews(null);

	const showSelection = !isResultLoading && !error && results.length;

	/** NAVIGATION **/

	const onNextItem = () => {
		if (itemIndex < results.length - 1) setItemIndex(itemIndex + 1);
		resetReviews();
		checkLoadNextPage();
	};

	const onPrevItem = () => {
		if (itemIndex !== 0) setItemIndex(itemIndex - 1);
		resetReviews();
		checkLoadNextPage();
	};

	const checkLoadNextPage = () => {
		if (!nextPageToken) return;
		if (nextLoadIndex && itemIndex < nextLoadIndex) return;
		if (nextLoadIndex === itemIndex) loadNextPageResults();
	};

	/** GESTURES **/

	const onTouchStart = (e) => {
		setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
		setTouchStart(e.targetTouches[0].clientX);
	};
	
	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
	
	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if (isLeftSwipe) {
			onNextItem();
		} else if (isRightSwipe) {
			onPrevItem();
		}
	};

	/** ACTIONS **/

	const onLinkItem = () => {
		gotoPlaceLink(results[itemIndex].place_id);
		logLinkClick();
	};

	const onLikeItem = () => {
		// TODO
		logLikeClicked();
	};

	const onNavigateItem = () => {
		gotoGoogleMapNavMode(coordinates.lat, coordinates.lng, results[itemIndex].lat, results[itemIndex].lng);
		logNavigateClick();
	};
	
	return (
		<div class="main" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
			<NavBar
				place={place}
				onPlaceChange={onPlaceChange}
				onGPSClick={getGPSLocation}
				hasGPS={hasGps}
				setError={setError}
			/>
			<FilterBar
				group={groupedBy}
				setGroup={onGroup}
				filter={filterBy}
				setFilter={onFilterChange}
				distance={distance}
				setDistance={onDistanceChange}
			/>
			{!isResultLoading && <LogoSection
				error={error}
				filter={filterBy}
				placeCount={results.length}
				hasSearched={hasSearched}
			/>}
			{showSelection && <SelectionSection
				coordinates={coordinates}
				places={results}
				index={itemIndex}
				onScrollLeft={onPrevItem}
				onScrollRight={onNextItem}
				isGrouped={!!groupedBy}
				isAtStart={itemIndex === 0}
				isAtEnd={itemIndex === results.length -1}
				placeSelected={placeSelected}
				setPlaceSelected={setPlaceSelected}
			/>}
			{showSelection && <ReviewSection
				isGrouped={!!groupedBy}
				reviews={reviews}
				isLoading={isReviewLoading}
				onClick={loadReview}
				placeSelected={placeSelected}
			/>}
			{showSelection && !groupedBy && <ActionSection
				onLinkCopy={onLinkItem}
				onLike={onLikeItem}
				onNavigate={onNavigateItem}
			/>}
			{isResultLoading && <div class="content">
				<Spinner />
			</div>}
			{(error || !results.length) && <div class="content"></div>}
			<Footer coords={coordinates} />
		</div>
	);
}

render(<App />, document.getElementById('app'));
