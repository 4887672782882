const ActionSection = (props) => {
  const { onLinkCopy, onLike, onNavigate } = props;
  return (
    <div class="action">
      <div class="action-container">
        <div class="action-btn-container">
          <button onClick={onLinkCopy}>🔗️</button>
        </div>
        <div class="action-btn-container">
          <button onClick={onLike}>❤️️</button>
        </div>
        <div class="action-btn-container">
          <button onClick={onNavigate}>🚘</button>
        </div>
      </div>
    </div>
  );
};

export default ActionSection;
