const FoodGridSingle = (props) => {
  const { coordinates, groupView, place, onClick } = props;
  
  const generateRating = () => {
    const stars = Math.round(place.rating);
    return '⭐️'.repeat(stars);
  };

  const generateDistance = () => {
    const lat1 = coordinates.lat;
    const lng1 = coordinates.lng;
    const lat2 = place.lat;
    const lng2 = place.lng;

    if ((lat1 === lat2) && (lng1 === lng2)) {
      return 0;
    } else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lng1 - lng2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return Math.round(dist);
    }
  };

  return (
    <div class={groupView ? 'thumb-primary-group' : 'thumb-primary'} onClick={() => onClick?.(place)}>
      <img src={place.img} />
      <div class={groupView ? 'thumb-info-box-group' : 'thumb-info-box'}>
        <h1>{place.name}</h1>
        <p>{generateDistance()} km</p>
        <h4>{generateRating()}</h4>
      </div>
    </div>
  );
};

export default FoodGridSingle;
