export const DIET_TAG_VEGETARIAN = 'd:veg';
export const DIET_TAG_NON_VEGETARIAN = 'd:non';

export const getFilterCuisineValue = (filter) => {
  if (!filter?.cuisine) return null;
  const chunks = filter.cuisine.split(':');
  return chunks.length > 1 ? chunks[1] : null;
};

export const filterIsVegetarian = (filter) => {
  return filter?.diet === DIET_TAG_VEGETARIAN;
};
