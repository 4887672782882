const userId = '';

const ReviewItem = (props) => {
  const { review } = props;
  const own = review.userid === userId;

  return (
    <div class="review-item">
      {!own && <>
        <img src="https://cdn4.vectorstock.com/i/1000x1000/06/18/male-avatar-profile-picture-vector-10210618.jpg" />
        <div class="review-item-dialog">
          <div class="review-item-dialog-top">
            <h1 class="full-flex">{review.name}</h1>
            <p>{review.ts}</p>
          </div>
          <p>{review.body}</p>
        </div>
      </>}
      {own && <>
        <div class="full-flex"></div>
        <div class="review-item-dialog">
          <div class="review-item-dialog-top">
            <p class="full-flex">{review.ts}</p>
            <h1>Me</h1>
          </div>
          <p>{review.body}</p>
        </div>
        <img src="https://cdn4.vectorstock.com/i/1000x1000/06/18/male-avatar-profile-picture-vector-10210618.jpg" />
      </>}
    </div>
  );
};

export default ReviewItem;
