const LogoSection = (props) => {
  const { placeCount, error, filter, hasSearched } = props;

  const getSubText = () => {
    if (!placeCount) {
      return 'No';
    } else if (placeCount > 50) {
      return 'Plenty of';
    } else if (placeCount > 20) {
      return 'Many';
    } else {
      return 'Some';
    }
  };

  return (
    <div class="logo-content">
      <h1 class="logo-heading">Where Got Food?</h1>
      {!error && !placeCount && !hasSearched &&
        <p class="logo-subtitle"><em>Enter location</em> to get started.</p>
      }
      {!error && (placeCount || placeCount === 0) && hasSearched &&
        <p class="logo-subtitle"><em>{getSubText()}</em> options near you.</p>
      }
      {error && <p class="logo-error">{error}</p>}
    </div>
  );
};

export default LogoSection;
